<template>
    <v-container>
        <AdBTWR v-if="!user.subscribed"/>

        <v-row justify="center" class="mt-10">
            <v-col cols="12" sm="6" class="text-center">
                <v-card>
                    <h1 class="page-header font-weight-light pt-5">Verify Email Address</h1>

                    <v-progress-circular
                            size="72"
                            class="ma-10"
                            color="primary"
                            indeterminate
                            v-if="verificationLoading"
                    >
                    </v-progress-circular>

                    <v-fab-transition>
                        <div class="stack-icon pa-10" v-if="verificationComplete">
                            <v-icon
                                    color="white"
                                    size="90"
                                    class="stack-icon-bottom"
                            >
                                mdi-circle
                            </v-icon>

                            <v-icon
                                    size="95"
                                    color="success"
                                    class="stack-icon-top"
                            >
                                mdi-check-circle
                            </v-icon>
                        </div>
                    </v-fab-transition>

                    <h3 class="title font-weight-light pb-10" v-if="verificationComplete">
                        Thank you for verifying your email address.
                        <!--You may now <router-link to="/login">sign in</router-link>.-->
                    </h3>

                    <h3 class="subtitle-1 font-weight-light pb-10" v-if="verificationComplete">
                        Redirecting you to the sign in page...
                    </h3>

                    <v-fab-transition>
                        <div class="stack-icon pa-10" v-if="verificationFailed">
                            <v-icon
                                    color="white"
                                    size="90"
                                    class="stack-icon-bottom"
                            >
                                mdi-circle
                            </v-icon>

                            <v-icon
                                    size="95"
                                    color="error"
                                    class="stack-icon-top"
                            >
                                mdi-close-circle
                            </v-icon>
                        </div>
                    </v-fab-transition>

                    <h3 class="title font-weight-light pb-5" v-if="verificationFailed">
                        An error occurred while verifying your email address.
                    </h3>

                    <h4 class="subtitle-1 font-weight-light pb-10" v-if="verificationFailed && verificationFailedMessage">
                        {{ verificationFailedMessage }}
                    </h4>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<style>
    .stack-icon {
        display: flex;
    }

    .stack-icon-top {
        width: 100%;
        margin-left: -100%;
    }

    .stack-icon-bottom {
        width: 100%;
        padding-top: 1px;
    }
</style>

<script>
    import axios from 'axios';
    import config from '../config';
    import AdBTWR from '@/components/ads/BTWR.vue';
    import {useTitle} from '@vueuse/core';

    export default {
        name: 'VerifyEmail',

        props: ['user'],

        components: {AdBTWR},

        data: () => ({
            verificationLoading: false,
            verificationComplete: false,
            verificationFailed: false,
            verificationFailedMessage: null,
        }),

        methods: {
            verifyEmail() {
                this.verificationLoading = true;

                const formData = {
                    username: this.$route.query.username,
                    email: this.$route.query.email
                };

                axios
                    .post(config.API_LOCATION + '/verify-email', formData)
                    .then(response => {
                        if (response.data.success) {
                            this.verificationComplete = true;
                            this.verificationFailed = false;
                            this.verificationFailedMessage = null;

                            setTimeout(() => {
                                this.$router.push({name: 'login'});
                            }, 3000);
                        }
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 401) {
                            this.$emit('unauthorized');
                        }
                        this.verificationFailedMessage = err.response.data.message;
                        this.verificationFailed = true;
                        this.verificationComplete = false;
                    })
                    .then(() => {
                        this.verificationLoading = false;
                    });
            }
        },

        mounted() {
            useTitle('Verify Email - myGMRS.com');

            this.verifyEmail();


            // setTimeout(() => {
            //     this.verificationLoading = false;
            //     this.verificationComplete = true;
            // }, 2000)
        }
    }
</script>
